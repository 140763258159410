import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../images/logo/logo.svg'
import './style.scss'

const HeaderBottom = props => {

    const [responsive] = useState(false)

    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <div className="row">
                        <div className="col-lg-4 col-md-11 col-sm-6 col-8">
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={logo} alt="Exodus Capital - Investimentos Logo" />
                                </NavLink>
                            </div>
                        </div>
                        <div className={responsive ? "col-lg-8 responsiveWrapper active" : "col-lg-8 responsiveWrapper"}>
                            <ul className="mainMenuWrap">
                                <li><NavLink exact to='/'>Home</NavLink>
                                </li>
                                <li><NavLink exact to='/contact'>Contato</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderBottom