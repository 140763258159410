/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo/logo.png'
import logoAnbima from '../../images/logo/logoA.png'
import logoCfp from '../../images/logo/logoC.png'
import './style.scss'
const footerLinks = [
    {
        title: 'Serviços', menus: [
            { name: 'Gestão Financeira', route: '' },
            { name: 'Investimentos', route: '' },
            { name: 'Aposentadoria', route: '' },
            { name: 'Diminuir gastos', route: '' },
        ]
    },
    {
        title: 'Contato', menus: [
            { name: 'Escritório:' },
            { name: 'Av. Julio de Sá 200, Barra da Tijuca - Rio de Janeiro', },
            { name: 'Phone: +55 21 964774736', },
            { name: 'Email: contato@exoduscapital.com.br', },
        ]
    },
]

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                                <p>Somos especializados em fornecer planejamento pessoal de alta qualidade em muitos aspectos da gestão de patrimônio, aconselhando clientes com recursos financeiros muito diferentes e de todas as idades.</p>
                            </div>
                        </div>
                        {footerLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo2">
                                <p>Profissionais certificados</p>
                                <Link to="/">
                                    <img src={logoAnbima} alt="" />
                                </Link>
                                <Link to="/">
                                    <img src={logoCfp} alt="" />
                                </Link>
                                <p>Planejadores financeiros e especialistas em investimentos à sua disposição</p>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>Política de Privacidade | © 2020 Exodus Capital. Todos os direitos reservados.</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li>
                                        <a href="https://instagram.com/exodus.capital" alt="facebook link"><i className="fa fa-instagram" aria-hidden="true">
                                            </i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea