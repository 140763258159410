import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import signature from '../../../images/about/1.png'

// images
import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'
import portfolio4 from '../../../images/studies/4.jpg'
import portfolio5 from '../../../images/studies/5.jpg'

const aboutText = [
    { text: 'Um plano financeiro cria um roteiro para o seu dinheiro e ajuda você a atingir seus objetivos.' },
    { text: 'O plano financeiro é uma imagem abrangente de suas finanças atuais, seus objetivos financeiros e quaisquer estratégias que você definiu para atingir esses objetivos. Um bom planejamento financeiro deve incluir detalhes sobre seu fluxo de caixa, poupança, dívida, investimentos, seguros e quaisquer outros elementos de sua vida financeira.' },
    { text: 'Nossa Equipe conta com analistas e profissionais certificados e preparados nas mais diversas areas de Gestão Financeira e Investimentos.' },
]

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'Cuidamos da sua Saúde Financeira',
        subTitle: '',
        text: 'Gestão Financeira & Investimentos',
        button: 'Saiba mais'
    },
    {
        images: 'slideWrapperTwo',
        title: 'We Fight For Your Justice',
        subTitle: 'As Like A Friend.',
        text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Planejamento Sucessório',
        content: 'Desenvolvimento de uma estratégia para lidar com seus ativos após a morte. Isso inclui os instrumentos jurídicos e as estruturas que você cria para transferir seus ativos em caso de morte.'
    },
    {
        icon: 'flaticon-wounded',
        title: 'Gestão de Riscos',
        content: 'É o processo de identificar, medir e tratar o risco pessoal (incluindo, mas não se limitando a seguro), seguido pela implementação do plano de tratamento e monitoramento das mudanças ao longo do tempo.'
    },
    {
        icon: 'flaticon-employee',
        title: 'Planejamento Aposentadoria',
        content: 'O planejamento de aposentadoria inclui a identificação de fontes de receita, estimativa de despesas, implementação de um programa de poupança e gerenciamento de ativos e riscos.'
    },
    {
        icon: 'flaticon-thief',
        title: 'Gestão Tributária',
        content: 'O planejamento tributário é a análise e organização da situação financeira de uma pessoa, a fim de maximizar os incentivos fiscais e minimizar as obrigações fiscais de uma forma legal e eficiente.'
    },
    {
        icon: 'flaticon-scale',
        title: 'Consultoria em Investimentos',
        content: 'A gestão de investimentos é aproveitar ao máximo o seu dinheiro: não importa quanto você tenha em seu portfólio, o importante é garantir que cada real seja otimizado. Alocação estratégica do seu patrimônio.'
    },
    {
        icon: 'flaticon-house',
        title: 'Gestão Financeira',
        content: 'Do imposto de renda à gestão dos recursos com eficácia, nós lhe auxiliamos a controlar e planejar efetivamente os seus gastosu.'
    },
]

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
]

const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" />
            <Service className="serviceArea" />
            <About
                className="aboutArea"
                title="O Planejamento Financeiro"
                images={about}
                signature={signature}
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea"
                title="Como podemos te ajudar"
                subTitle="Areas de atuação"
                services={services}
            />
            <ContactArea
                className="contactArea"
            />
            {/*<BlogArea
                className="blogArea"
                title="Notícias e Conhecimento"
                subTitle="Blog Financeiro"
            />*/}
            <NewsLetter
                className="newsLetterArea"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default HomePageOne